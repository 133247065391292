import './the_carousel.scss'
import Glide from '@glidejs/glide'
import '@glidejs/glide/dist/css/glide.core.min.css'
// Import static files here. For instance: import './static/logo.png'

export default () => {
  const glideElement = document.querySelector('.glide')
  if (glideElement) {
    new Glide('.glide', {
      type: 'carousel',
      animationDuration: 600,
      perView: 1
    }).mount()
  }
}
