import './mixitup_container.scss'
import mixitup from 'mixitup'

// Import static files here. For instance: import './static/logo.png'
const mixitupContainer = document.querySelector('.js-mixitup_container')
if (mixitupContainer) {
  mixitup(mixitupContainer, {
    controls: {
      toggleLogic: 'or'
    }
  })
  // Mobile Dropdown
  const dropdown = document.querySelector(
    '.js-mixitup_container-categories_mobile-title'
  )
  if (dropdown) {
    dropdown.addEventListener('click', event => {
      event.preventDefault()
      const options = document.querySelector(
        '.js-mixitup_container-categories_mobile-options'
      )
      options.classList.toggle('hide')
    })
  }
}
