import './locale_selector.scss'
// Import static files here. For instance: import './static/logo.png'
const registerHideOnClickOutside = localeSelector => {
  const outsideClickListener = event => {
    if (!localeSelector.contains(event.target) && isVisible(localeSelector)) {
      closeLocaleSelector(event, localeSelector)
      document.removeEventListener('click', outsideClickListener)
    }
  }
  document.addEventListener('click', outsideClickListener)
}

const isVisible = localeSelector =>
  !!localeSelector &&
  !!(
    localeSelector.offsetWidth ||
    localeSelector.offsetHeight ||
    localeSelector.getClientRects().length
  )

const closeLocaleSelector = (event, localeSelector) => {
  localeSelector.classList.remove('open')
}

const openLocaleSelector = (event, localeSelector) => {
  localeSelector.classList.add('open')
  registerHideOnClickOutside(localeSelector)
}

const toggleLocaleSelector = (event, localeSelector) => {
  if (localeSelector.classList.contains('open')) {
    closeLocaleSelector(event, localeSelector)
  } else {
    openLocaleSelector(event, localeSelector)
  }
}

export default () => {
  const localeSelectors = Array.from(
    document.querySelectorAll('.js-locale_selector')
  )
  localeSelectors.forEach(localeSelector =>
    localeSelector.addEventListener('click', event =>
      toggleLocaleSelector(event, localeSelector)
    )
  )
}
