import './stripe_form.scss'
// Import static files here. For instance: import './static/logo.png'

export default () => {
  const stripeHiddenField = document.querySelector('.js-publishable-key')
  const stripeHiddenSessionField = document.querySelector('.js-session-id')
  if (stripeHiddenField) {
    const stripe = Stripe(stripeHiddenField.dataset.key)
    const stripeButton = document.querySelector('.js-stripe_button')

    stripeButton.addEventListener('click', event => {
      stripe
        .redirectToCheckout({
          sessionId: stripeHiddenSessionField.dataset.id
        })
        .then(function (result) {
          alert(result.error.message)
        })
    })
  }
}
