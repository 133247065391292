import './shop_squares.scss'
// Import static files here. For instance: import './static/logo.png'
const updateShopSquares = (shopSquares, data) => {
  const formattedAmount = shopSquares.querySelector(
    '.js-shop_squares-formatted_amount'
  )
  if (formattedAmount) {
    formattedAmount.innerHTML = data.formatted_amount
  }
  const pictureDiv = shopSquares.querySelector('.js-shop_squares-picture_div')
  if (pictureDiv) {
    const currentVariantId = parseInt(pictureDiv.dataset.variant_id, 10)
    if (currentVariantId !== data.variant_id) {
      const updatedPictureUrl = `url("${data.picture_url}")`
      pictureDiv.style.backgroundImage = updatedPictureUrl
      pictureDiv.dataset.variant_id = data.variant_id
    }
  }
}

export default () => {
  const shopSquares = document.querySelector('.js-shop_squares')
  if (shopSquares) {
    const type = shopSquares.dataset.type
    const url = shopSquares.dataset.quote_url
    const inputs = Array.from(shopSquares.querySelectorAll('input'))
    if (shopSquares.querySelector('select')) {
      inputs.push(shopSquares.querySelector('select'))
    }
    inputs.forEach(input =>
      input.addEventListener('change', event => {
        const authenticityToken = shopSquares.querySelector(
          'input[name="authenticity_token"]'
        ).value
        let params
        if (type === 'samples') {
          const variantIds = Array.from(
            shopSquares.querySelectorAll('input[type="checkbox"]:checked')
          ).map(checkbox => checkbox.value)
          params = { order_item: { variant_id: variantIds } }
        } else {
          const quantity = shopSquares.querySelector('#order_item_quantity')
            .value
          const variantId = shopSquares.querySelector('#order_item_variant_id')
            .value
          params = { order_item: { quantity: quantity, variant_id: variantId } }
        }
        params = { ...params, authenticity_token: authenticityToken }
        fetch(url, {
          method: 'post',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify(params)
        })
          .then(response => response.json())
          .then(data => updateShopSquares(shopSquares, data))
      })
    )
  }
}
