import './paypal_button.scss'
// Import static files here. For instance: import './static/logo.png'

export default () => {
  document.addEventListener('DOMContentLoaded', event => {
    const paypalInfo = document.querySelector('.js-paypal_info')

    if (paypalInfo) {
      const orderIdentifier = paypalInfo.dataset.identifier
      const authenticityToken = paypalInfo.dataset.authenticity_token

      paypal
        .Buttons({
          style: {
            layout: 'horizontal',
            color: 'blue',
            size: 'responsive',
            shape: 'rect',
            label: 'paypal',
            tagline: false
          },
          createOrder: function () {
            return fetch(`/orders/${orderIdentifier}/paypal`, {
              method: 'post',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify({ authenticity_token: authenticityToken })
            })
              .then(function (res) {
                return res.json()
              })
              .then(function (data) {
                return data.id
              })
          },
          onApprove: function (data) {
            return fetch(`/orders/${orderIdentifier}/capture_paypal_payment`, {
              method: 'post',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify({
                orderID: data.orderID,
                authenticity_token: authenticityToken
              })
            })
              .then(function (res) {
                return res.json()
              })
              .then(function (details) {
                window.location.replace(
                  `/orders/${orderIdentifier}/confirmation`
                )
              })
          }
        })
        .render('.js-paypal_button')
    }
  })
}
