import Rails from 'rails-ujs'
import './cart_item.scss'
// Import static files here. For instance: import './static/logo.png'
export default () => {
  const cartItems = Array.from(document.querySelectorAll('.js-cart_item'))
  cartItems.forEach(cartItem => {
    const form = cartItem.querySelector('form')
    const quantityInput = form.querySelector('#order_item_quantity')
    quantityInput.addEventListener('change', event =>
      Rails.fire(form, 'submit')
    )
  })
}
