import './mobile_sidebar.scss'
// Import static files here. For instance: import './static/logo.png'
const registerHideOnClickOutside = () => {
  const outsideClickListener = event => {
    const mobileSidebar = document.querySelector('.js-mobile_sidebar')
    const overlay = mobileSidebar.querySelector('.js-mobile_sidebar_overlay')
    const clickOnBurger =
      event.target.parentElement &&
      event.target.parentElement.classList.contains('js-burger_icon')
    if (
      !clickOnBurger &&
      !overlay.contains(event.target) &&
      mobileSidebar.classList.contains('open')
    ) {
      closeSidebar()
      document.removeEventListener('click', outsideClickListener)
    }
  }
  document.addEventListener('click', outsideClickListener)
}

const openSidebar = () => {
  const mobileSidebar = document.querySelector('.js-mobile_sidebar')
  mobileSidebar.classList.add('open')
  document.querySelector('body').style.overflow = 'hidden'
  registerHideOnClickOutside()
}

const closeSidebar = () => {
  const mobileSidebar = document.querySelector('.js-mobile_sidebar')
  mobileSidebar.classList.remove('open')
  document.querySelector('body').style.overflow = ''
}

export default () => {
  const mobileSidebar = document.querySelector('.js-mobile_sidebar')
  if (mobileSidebar) {
    const openButton = mobileSidebar.querySelector('.js-burger_icon')
    openButton.addEventListener('click', openSidebar)
    const closeButton = mobileSidebar.querySelector('.js-close_icon')
    closeButton.addEventListener('click', closeSidebar)
  }
}
