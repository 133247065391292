import Rails from 'rails-ujs'
// Pages
import 'config'
import 'pages'
import 'reviews'
import 'orders'
import 'stores'
import 'articles'
import 'posts'
import 'products'
// Components
import 'components/button/button'
import 'components/mixitup_container/mixitup_container'
import 'components/shop_item/shop_item'
import 'components/squares/squares'
import 'components/star_icon/star_icon'
import 'components/store_item/store_item'
import 'components/review_item/review_item'
import 'components/caret_icon/caret_icon'
import 'components/breadcrumb_trail/breadcrumb_trail'
import 'components/product_presentation/product_presentation'
import 'components/logo_gold_icon/logo_gold_icon'
import 'components/cart_icon/cart_icon'
import 'components/cart_in_nav/cart_in_nav'
import 'components/subnavbar/subnavbar'
import 'components/banner/banner'
import 'components/exchange_icon/exchange_icon'
import 'components/footer/footer'
import 'components/facebook_icon/facebook_icon'
import 'components/instagram_icon/instagram_icon'
import 'components/youtube_icon/youtube_icon'
import 'components/footer_patchwork/footer_patchwork'
import 'components/burger_icon/burger_icon'
import 'components/close_icon/close_icon'
import 'components/secondary_banner/secondary_banner'
import 'components/press/press'
import 'components/review_section/review_section'
import 'components/stores_global_section/stores_global_section'
import 'components/logo_neiman/logo_neiman'
import 'components/logo_theodora/logo_theodora'
import 'components/logo_kobberger/logo_kobberger'
import 'components/logo_rive_gauche/logo_rive_gauche'
import 'components/logo_les_senteurs/logo_les_senteurs'
import 'components/partners_section/partners_section'
import 'components/startuplike_blocks/startuplike_blocks'
import 'components/be_unique_icon/be_unique_icon'
import 'components/candle_specs/candle_specs'
import 'components/order_checkout_trail/order_checkout_trail'
import 'components/arrow_icon/arrow_icon'
import 'components/cart_footer/cart_footer'
import 'components/dbox_presentation/dbox_presentation'
import 'components/heart_icon/heart_icon'
import 'components/guide_icon/guide_icon'
import 'components/gift_icon/gift_icon'
import 'components/carousel_slide/carousel_slide'
import 'components/introduction/introduction'
import 'components/section_title/section_title'
import 'components/breadcrumb/breadcrumb'
import 'components/sponsor_presentation/sponsor_presentation'
import 'components/order_detail/order_detail'
import 'components/potion_icon/potion_icon'
import 'components/tint_icon/tint_icon'
import 'components/book_icon/book_icon'
import ManageShopSquares from 'components/shop_squares/shop_squares'
import LocaleSelector from 'components/locale_selector/locale_selector'
import Navbar from 'components/navbar/navbar'
import MobileSidebar from 'components/mobile_sidebar/mobile_sidebar'
import ManageStripePayment from 'components/stripe_form/stripe_form'
import ManagePaypal from 'components/paypal_button/paypal_button'
import ManageCart from 'components/cart/cart'
import ManageCartItems from 'components/cart_item/cart_item'
import Carousel from 'components/the_carousel/the_carousel'
import Tabs from 'components/tab/tab'
import Video from 'components/video_frame/video_frame'
// Images
import 'static/footer_patchwork.jpg'
import 'static/footer_patchwork_mobile.jpg'
import 'static/candles_section_1.jpg'
import 'static/candles_section_1_mobile.jpg'
import 'static/candles_section_2.jpg'
import 'static/candles_section_2_mobile.jpg'
import 'static/concept_section_1.jpg'
import 'static/concept_section_1_mobile.jpg'
import 'static/concept_section_2.jpg'
import 'static/concept_section_2_mobile.jpg'
import 'static/concept_section_3.jpg'
import 'static/concept_section_3_mobile.jpg'
import 'static/creator_section_1.jpg'
import 'static/creator_section_1_mobile.jpg'
import 'static/creator_section_2.jpg'
import 'static/creator_section_2_mobile.jpg'
import 'static/creator_section_3.jpg'
import 'static/creator_section_3_mobile.jpg'
import 'static/custom_section_1.jpg'
import 'static/custom_section_1_mobile.jpg'
import 'static/home_banner.jpg'
import 'static/home_banner_mobile.jpg'
import 'static/home_section_1.jpg'
import 'static/home_section_1_mobile.jpg'
import 'static/home_section_2.jpg'
import 'static/home_section_2_mobile.jpg'
import 'static/navbar_concept.jpg'
import 'static/navbar_concept_mobile.jpg'
import 'static/navbar_creator.jpg'
import 'static/navbar_creator_mobile.jpg'
import 'static/navbar_philosophy.jpg'
import 'static/navbar_philosophy_mobile.jpg'
import 'static/navbar_store.jpg'
import 'static/navbar_store_mobile.jpg'
import 'static/navbar_press.jpg'
import 'static/navbar_press_mobile.jpg'
import 'static/navbar_faq.jpg'
import 'static/navbar_faq_mobile.jpg'
import 'static/philosophy_section_1.jpg'
import 'static/philosophy_section_1_mobile.jpg'
import 'static/philosophy_section_2.jpg'
import 'static/philosophy_section_2_mobile.jpg'
import 'static/philosophy_section_3.jpg'
import 'static/philosophy_section_3_mobile.jpg'
import 'static/press_logos.png'
import 'static/press_logos_mobile.png'
import 'static/product_show_section_1.jpg'
import 'static/product_show_section_1_mobile.jpg'
import 'static/product_show_section_2.jpg'
import 'static/product_show_section_2_mobile.jpg'
import 'static/quotes.png'
import 'static/quotes_mobile.png'
import 'static/samples_section_1.jpg'
import 'static/samples_section_1_mobile.jpg'
import 'static/sponsorship_section_1.jpg'
import 'static/sponsorship_section_1_mobile.jpg'
import 'static/startuplikeblock_1.svg'
import 'static/startuplikeblock_2.svg'
import 'static/startuplikeblock_3.svg'
import 'static/startuplikeblock_4.svg'
import 'static/worldmap.png'
import 'static/worldmap_mobile.png'
import 'static/default_meta_image.jpg'

import 'components/article_extract/article_extract'
import 'components/favicon/favicon'
import 'components/pagy/pagy'
import 'components/seo_description/seo_description'
import 'components/posts_showcase/posts_showcase'
// END OF IMPORTS (DO NOT DELETE)

Rails.start()
LocaleSelector()
Navbar()
MobileSidebar()
ManageCartItems()
ManageCart()
ManageStripePayment()
ManagePaypal()
setTimeout(() => Carousel(), 1000)
ManageShopSquares()
Tabs()
Video()
