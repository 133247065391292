import './navbar.scss'
// Import static files here. For instance: import './static/logo.png'
export default () => {
  const navbarTransparent = document.querySelector('.js-navbar_transparent')
  if (navbarTransparent) {
    window.addEventListener('scroll', event => {
      if (window.scrollY > 0) {
        navbarTransparent.classList.remove('navbar-transparent')
      } else {
        navbarTransparent.classList.add('navbar-transparent')
      }
    })
  }
}
