import './video_frame.scss'
// Import static files here. For instance: import './static/logo.png'
export default () => {
  const videoFrame = document.querySelector('.js-video_frame')
  if (videoFrame) {
    const videoCover = videoFrame.querySelector('.js-video_cover')
    const videoSrc = videoFrame.dataset.video_src
    const iframe = videoFrame.querySelector('iframe')
    videoCover.addEventListener('click', event => {
      iframe.setAttribute('src', videoSrc)
      videoCover.remove()
    })
  }
}
