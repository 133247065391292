import './tab.scss'
// Import static files here. For instance: import './static/logo.png'

const onTabClick = event => {
  const selectedTab = event.target.closest('.js-tab_nav')
  const selectedPanel = document.getElementById(selectedTab.dataset.target)
  selectedTab.classList.add('active')
  selectedPanel.classList.add('active')

  const otherTabs = Array.from(
    selectedTab.parentElement.querySelectorAll('.js-tab_nav')
  ).filter(tab => tab.dataset.target !== selectedTab.dataset.target)
  otherTabs.forEach(otherTab => {
    const otherPanel = document.getElementById(otherTab.dataset.target)
    otherTab.classList.remove('active')
    otherPanel.classList.remove('active')
  })
}

export default () => {
  const tab = document.querySelector('.js-tab')
  if (tab) {
    const tabIcons = Array.from(tab.querySelectorAll('.js-tab_nav'))
    tabIcons.forEach(tab => tab.addEventListener('click', onTabClick))
  }
}
