import { htmlToElement } from '../../config'
import './cart.scss'
// Import static files here. For instance: import './static/logo.png'

export default () => {
  const cart = document.querySelector('.js-cart')
  if (cart) {
    document.addEventListener('ajax:success', event => {
      const cartFooter = cart.querySelector('.js-cart_footer')
      const action = event.target.dataset.action
      switch (action) {
        case 'apply_coupon': {
          const htmlResponse = event.detail[2].response
          cartFooter.outerHTML = htmlResponse
          break
        }
        case 'order_item_update': {
          const cartItem = event.target.closest('.js-cart_item')
          const htmlResponse = event.detail[2].response
          const nodeResponse = htmlToElement(event.detail[2].response)
          cartFooter.outerHTML = htmlResponse
          const updatedOrderItem = JSON.parse(nodeResponse.dataset.cart)
            .order_items[cartItem.dataset.order_item_id]
          if (updatedOrderItem) {
            cartItem.querySelector('.js-cart_item_total').innerHTML =
              updatedOrderItem.total
          } else {
            cartItem.remove()
          }
          break
        }
        case 'order_item_destroy': {
          const cartItem = event.target.closest('.js-cart_item')
          const htmlResponse = event.detail[2].response
          cartItem.remove()
          cartFooter.outerHTML = htmlResponse
          break
        }
        default:
          return false
      }
    })
  }
}
